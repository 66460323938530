html {
    scroll-behavior: smooth;
}

body {
    font-family: 'Raleway';
    line-height: 1.5rem;
}

a {
    &:hover {
        color: $red !important;
    }
}
h1,h2,h3,h4 {
    font-family: 'JosefinSans-Regular';
}
h1,h2,h3,h4 {
    b {
        font-family: 'JosefinSans-Bold';
        font-weight: 700;
    }
}
p {
    line-height: 2rem;
}

.btn {
    border-width: 3px;
    border-radius: 0;
    transition: all 0.2s ease-out 0s;
    min-height: 0px;
    min-width: 0px;
    line-height: 21px;
    border-width: 4px;
    margin: 20px 0px;
    padding: 12px 30px;
    letter-spacing: 0px;
    text-transform: uppercase;
    font-size: 0.75rem;
    font-weight: 700;
    // &.btn-outline-light {
    //     &:hover {
    //         color: #212529;
    //     }
    // }
}

.text-gray {
    color: #f4f4f4;
}
ul {
    padding: 0;
}
.form-control, .form-control:focus {
    background: rgba(100, 100, 100, 0.08);
    border: 1px solid transparent;
    line-height: 1.5em;
    width: 100%;
    padding: 13px 10px;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
}

button[type=submit] {
    background: transparent;
    border: 0px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    padding: 12px 30px;
    color: white;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 21px;
    border: 4px solid white;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    -ms-transition: all 0.3s linear;
    transition: all 0.3s linear;
}

.error {
    color: $red;
}

.lh-6 {
    line-height: 6rem;
}