.section {
    position: relative;
    background-color: $gray;
    padding: 110px 0;
    &:after {
        display: block;
        content: "";
        position: absolute;
        max-width: 78px;
        height: 26px;
        top: 100%;
        left: 50%;
        margin-left: -38px;
        border-top: 26px solid $gray;
        border-left: 38px solid transparent;
        border-right: 38px solid transparent;
        border-bottom: none;
        z-index: 1;
    }
    &.no-arrow {
        &:after {
            display: none;
        }
    }
    &.yellow {
        background-color: $yellow;
        &:after {
            border-top-color: $yellow;
        }
        .title {
            color: $black;
        }
        .subtitle {
            color: $black;
        }
    }
    &.red {
        background-color: $red;
        &:after {
            border-top-color: $red;
        }
    }
    &.d-gray {
        background-color: $d-gray;
        &:after {
            border-top-color: $d-gray;
        }
    }
    &.white {
        background-color: $white;
        &:after {
            border-top-color: $white;
        }
    }

    &#slider {
        .carousel-inner {
            .carousel-item {
                .row {
                    align-items: center;
                    .text {
                        .title {
                            text-transform: unset;
                        }
                    }
                }
            }
        }
    }
    .title {
        font-size: 40px;
        line-height: 60px;
        text-transform: uppercase;
        @include media-breakpoint-down(md) {
            font-size: 2rem;
            line-height: 3rem;
        }
    }
    .subtitle {
        font-size: 24px;
        @include media-breakpoint-down(md) {
            font-size: 1.25rem;
        }
        &.underline {
            position: relative;
            display: block;
            padding-bottom: 20px;
            margin-bottom: 16px;
            &:after {
                content:'';
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: $white;
                height: 1px;
                width: 54%;
            }
        }
    }
    .card {
        background-color: rgba(100, 100, 100, 0.2);
        padding: 4rem 1rem 1.75rem;
        border-bottom: 10px solid $red;
        .card-body {
            .card-text {
                color: $grey-x-light;
            }
            .card-list {
                list-style: none;
                color: $subtitle;
                padding: 0 1.25rem;
                li {
                    border-bottom: 1px solid rgba(100, 100, 100, 0.5);
                    padding: 10px 0;
                    // &:last-child {
                    //     border-bottom: 1px solid rgba(100, 100, 100, 0.5);
                    // }
                }
            }
        }
        .card-footer {
            padding: 0 0 3rem;
            border: none;
            background: unset;
        }
    }
    img {
        border-radius: 5px;
    }
    &#why_authentic {
        img {
            max-width: unset !important;
        }
    }
    &#testimonials {
        .carousel-item {
            padding: 0 10%;
            p {
                font-size: 40px;
                line-height: 1.3;
                @include media-breakpoint-down(md) {
                    font-size: 1.5rem;
                }
            }
            .user {
                display: block;
                font-size: 30px;
                margin-top: 40px;
                line-height: 1.5;
                @include media-breakpoint-down(md) {
                    font-size: 1rem;
                }
                &:before, &:after {
                    content: ' - ';
                }
            }
        }
    }
    &#studio {
        .gallery {
            &>div {
                @include media-breakpoint-down(md) {
                    padding-bottom: 20px;
                }
            }
        }
    }
    &#gratz {
        ul {
            list-style: none;
            @include media-breakpoint-down(md) {
                padding-left: 20px;
            }
        }
    }
    &#tools {
        .card {
            .card-title {
                font-weight: bold;
                font-size: 1.5rem;
            }
        }
    }
    &#rates {
        .card {
            padding: 0;
            &.red {
                border-bottom: 10px solid $red;
                .card-header {
                    background-color: $red;
                }
                .card-price {
                    color: $red;
                }
            }
            &.green {
                border-bottom: 10px solid $green;
                .card-header {
                    background-color: $green;
                }
                .card-price {
                    color: $green;
                }
            }
            &.yellow {
                border-bottom: 10px solid $yellow;
                .card-header {
                    background-color: $yellow;
                }
                .card-price {
                    color: $yellow;
                }
            }
            &.gray {
                border-bottom: 10px solid $gray2;
                .card-header {
                    background-color: $gray2;
                }
                .card-price {
                    color: $gray2;
                }
            }
            .card-header {
                font-size: 1.5rem;
                color: $grey-dark;
                font-weight: 700;
                text-transform: uppercase;
            }
            .card-body {
                padding-bottom: 0;
            }
            .card-footer {
                .card-price {
                    font-size: 2.75rem;
                    line-height: 1.5rem;
                    font-weight: 700px;
                    span {
                        font-size: 20px;
                        color: $white;
                    }
                }
            }
        }
    }
    &#contact {
        .follow {
            p {
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
                &:before {
                    width: 30px;
                    height: 30px;
                    position: absolute;
                    margin-left: -40px;
                }
            }
            a {
                line-height: 2rem;
                color: $grey-light;
                @include media-breakpoint-down(md) {
                    font-size: 14px;
                }
            }
            .tel {
                p {
                    &:before {
                        content: url(https://cdn.originalpilates.co.uk/phone-icon-1.png?w=32);
                    }
                }
            }
            .wa {
                p {
                    &:before {
                        content: url(https://cdn.originalpilates.co.uk/wa.png?w=32);
                    }
                }
            }
            .fb {
                p {
                    &:before {
                        content: url(https://cdn.originalpilates.co.uk/fb.png?w=32);
                    }
                }
            }
            .ig {
                p {
                    &:before {
                        content: url(https://cdn.originalpilates.co.uk/instagram-logo.png?w=32);
                    }
                }
            }
        }
        #contact-form {
            input, textarea {
                color:#f9f9f9;
            }
            .btn-send {
                color: $white;
                background-color: transparent;
                border: 2px solid $white;
            }
        }
    }
    &#location {
        padding-bottom: 0;
        .map {
            margin-top: 70px;
        }
    }
    &.footer {
        @include media-breakpoint-down(md) {
            padding-bottom: 0;
        }
        .column {
            padding-bottom: 40px;
        }
        h4 {
            font-weight: 700;
            font-size: 1.125rem;
            min-height: 2.625rem;
            color: $grey-medium;
        }
        p {
            color: $grey-dark;
            font-size: 0.875rem;
            line-height: 1.5rem;
        }
    }
    &#footer-copy {
        background-color: $d-gray;
        padding: 10px 0;
        color:$grey-light;
    }
}