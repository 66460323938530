nav {
    &.navbar-expand-md {
        .navbar-brand {
            // @include media-breakpoint-down(md) {
                max-width: 50%;
            // }
        }
        .navbar-nav {
            .nav-item {
                .nav-link {
                    color: $grey-dark;
                    @include media-breakpoint-up(md) {
                        padding: 0px 40px;
                        font-size: 18px;
                    }
                }
                &:last-child(){
                    .nav-link {
                        padding-right: 0;
                    }
                }
            }
        }
    }
}