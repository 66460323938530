#slider {
    padding-bottom: 30px;
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }
    #carouselHero {
        height: 500px;
        @include media-breakpoint-down(md) {
            // height: 100vh;
        }
        .carousel-inner {
            @include media-breakpoint-down(md) {
                height: 100%;
            }
            .carousel-item {
                // height: 405px;
                .row {
                    height: 405px;
                    .text {
                        @include media-breakpoint-down(md) {
                            padding-top: 30px;
                        }
                        .title {
                            font-size: 60px;
                            line-height: 80px;
                            @include media-breakpoint-down(md) {
                                font-size: 40px;
                                line-height: 60px;
                            }
                        }
                        .descr {
                            font-size: 40px;
                            line-height: 60px;
                            @include media-breakpoint-down(md) {
                                font-size: 20px;
                                line-height: 30px;
                            }
                        }
                    }
                }
            }
        }
    }
}