@font-face {
    font-family: 'JosefinSans-Regular';
    src: url('../fonts/JosefinSans-Regular.eot');
    src: url('../fonts/JosefinSans-Regular.eot?#iefix') format('embedded-opentype'),
         url('../fonts/JosefinSans-Regular.svg#JosefinSans-Regular') format('svg'),
         url('../fonts/JosefinSans-Regular.ttf') format('truetype'),
         url('../fonts/JosefinSans-Regular.woff') format('woff'),
         url('../fonts/JosefinSans-Regular.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'JosefinSans-Bold';
    src: url('../fonts/JosefinSans-SemiBold.eot');
    src: url('../fonts/JosefinSans-SemiBold.eot?#iefix') format('embedded-opentype'),
         url('../fonts/JosefinSans-SemiBold.svg#JosefinSans-SemiBold') format('svg'),
         url('../fonts/JosefinSans-SemiBold.ttf') format('truetype'),
         url('../fonts/JosefinSans-SemiBold.woff') format('woff'),
         url('../fonts/JosefinSans-SemiBold.woff2') format('woff2');
    font-weight: normal;
    font-style: normal;
}