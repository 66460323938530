//  Table of Contents:
//
//   1. Global
//   2. Grid breakpoints
//   3. Base Typography
//   4. Typography Helpers
//   5. Abide
//   6. Accordion
//   7. Accordion Menu
//   8. ... 
// 1. Global
// ---------


/*!
 * Primary color settings
 */

$blue-light: #40B4DC;
$blue-dark: #0c202f;
$red: #b95454;
$black: #000;
$white: #fff;
$grey-x-light:#f4f4f4;
$grey-light:#b7b7b7;
$grey-medium:#8c8c8c;
$grey-dark:#333333;
$grey-form:#C8C8C8;

$gray: rgb(52,52,52);
$gray2: #cccccc;
$yellow: #F7C590;
$green: #937E62;
$d-gray: rgb(36,36,36);
$subtitle: #989898;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: ( xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1140px) !default;
@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints);

// @font-face {
//     font-family: 'Gilroy-ExtraBold';
//     src: url('Gilroy-ExtraBold.eot');
//     src: url('Gilroy-ExtraBold.eot?#iefix') format('embedded-opentype'),
//          url('Gilroy-ExtraBold.svg#Gilroy-ExtraBold') format('svg'),
//          url('Gilroy-ExtraBold.ttf') format('truetype'),
//          url('Gilroy-ExtraBold.woff') format('woff'),
//          url('Gilroy-ExtraBold.woff2') format('woff2');
//     font-weight: normal;
//     font-style: normal;
// }